
import { Options, Vue } from "vue-class-component";

@Options({
  data() {
    return {
      userInfo: null,
    };
  },
  beforeMount() {
    //Get user info
    this.userInfo = this.$store.getters.getUserInfo;
  },
})
export default class UserInfoBlock extends Vue {}
