
import { Options, Vue } from "vue-class-component";

@Options({
  data() {
    return {
      questionButton: "",
    };
  },
  props: {
    wikiSubjects: Array,
  },

  methods: {
    //Toggle question button on and off
    toggleQuestionButton() {
      if (this.questionButton == "openQuestion") {
        this.questionButton = "";
      } else {
        this.questionButton = "openQuestion";
      }
    },

    setWikiBookmark(item: string) {
      let routeData = this.$router.resolve({
        path: "/wiki",
        query: { item: item },
      });
      window.open(routeData.href, "_blank");
    },
  },
})
export default class QuestionButton extends Vue {}
