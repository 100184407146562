
// Import all blocks
import { Options, Vue } from "vue-class-component";
import UserInfoBlock from "@/components/profile/UserInfoBlock.vue";
import AvatarBlock from "@/components/profile/AvatarBlock.vue";
import ClassroomsOverviewBlock from "@/components/profile/ClassroomsOverviewBlock.vue";
import QuestionButton from "@/components/QuestionButton.vue";

@Options({
  data() {
    return {
      wikiSubjects: [],
    };
  },

  mounted() {
    this.updateQuestionButton();
  },

  methods: {
    updateQuestionButton() {
      this.wikiSubjects.push(
        { wikiButton: { name: this.$t("wiki.profile"), item: "profilePage" } },
        { wikiButton: { name: this.$t("wiki.avatar"), item: "avatarPage" } }
      );
    },
  },

  components: {
    UserInfoBlock,
    AvatarBlock,
    ClassroomsOverviewBlock,
    QuestionButton,
  },
})
export default class Profile extends Vue {}
