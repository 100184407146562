
import { Options, Vue } from "vue-class-component";
import { classAPI } from "@/utils/apiCalls";

@Options({
  data() {
    return {
      classrooms: [],
    };
  },
  async mounted() {
    //Get users classes
    this.classrooms = await classAPI.getMyClassrooms();
  },
})
export default class ClassroomsOverviewBlock extends Vue {}
