
import { Options, Vue } from "vue-class-component";
import { getAvatar, currentAvatar, saveAvatar, avatars } from "@/utils/Avatars";

@Options({
  data() {
    return {
      avatars: avatars,
      preview: currentAvatar(),
    };
  },
  methods: {
    getAvatar,
    /**
     * Set preview avatar
     * @param avatarId Id of to be previewed avatar
     */
    setPreview(avatarId: number): void {
      this.preview = getAvatar(avatarId);
    },
    saveAvatar,
  },
})
export default class AvatarBlock extends Vue {}
